/**
 * import the global components/resources
 */
import './app.js';
/**
 * import the custom js file for this route
 */
import './../scripts/404.custom.js';
/**
 * import web components used on this route
 */
